var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("thg-quote-list-card", {
              key: "dasd" + _vm.loadingAll,
              attrs: {
                loading: _vm.loadingAll,
                isLoadingDetail: _vm.isLoadingDetail,
                isLoadingMore: _vm.isLoadingMore,
                partnerId: _vm.partnerId
              },
              on: {
                set: _vm.set,
                loadMore: function loadMore($event) {
                  return _vm.loadMore({
                    partnerId: _vm.partnerId
                  })
                },
                refresh: function refresh($event) {
                  return _vm.refresh({
                    partnerId: _vm.partnerId
                  })
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _c("thg-quote-detail-card", {
              attrs: {
                loading: _vm.getloadingSelectedThg,
                selectedUser: _vm.selectedUser
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }