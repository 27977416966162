























import MDetailTable from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IThgVehicle } from "@/models/thg-vehicle.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Debug from "../utility/Debug.vue";
import MHeader from "../utility/mmmint/MHeader.vue";
import SideCard from "../utility/SideCard.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    MHeader,
    SideCard,
    LatestEntriesCardEmpty,
    MDetailViewGrid,
    MDetailTable,
    Debug
  }
})
export default class ThgVehicleSideCard extends mixins(PartnerFallbackMixin) {
  @Prop()
  value!: IThgVehicle;

  @Watch("value")
  async onValueChange() {
    try {
      await this.fetch();
    } catch (error) {
      handleError(error);
    }
  }

  get isLoading() {
    return this.value.isLoading;
  }

  get history() {
    return this.value?.autoRenewalHistory || [];
  }

  close() {
    this.$emit("close");
  }

  async fetch() {
    try {
      await this.value.fetch();
    } catch (error) {
      handleError(error);
    }
  }

  async mounted() {
    await this.fetch();
  }
}
