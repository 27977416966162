


































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { IControlElements } from "@/lib/types/tableWrapper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IThgVehicle } from "@/models/thg-vehicle.entity";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { ThgVehicleModule } from "@/store/modules/thg-vehicle.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import ThgVehicleDecommissionDialog from "./ThgVehicleDecommissionDialog.vue";
import ThgVehicleIsAutoRenewalDialog from "./ThgVehicleIsAutoRenewalDialog.vue";
import ThgVehicleSideCard from "./ThgVehicleSideCard.vue";
import ThgVehicleStatusDialog from "./ThgVehicleStatusDialog.vue";

@Component({
  components: {
    PaginatedTable,
    ThgVehicleDecommissionDialog,
    ThgVehicleIsAutoRenewalDialog,
    ThgVehicleStatusDialog,
    ThgVehicleSideCard
  }
})
export default class ThgVehicleTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = ThgVehicleModule;

  selected: IThgVehicle[] = [];
  selectedItem: IThgVehicle | null = null;

  setSelectedItem(item: IThgVehicle | null) {
    this.selectedItem = item;
    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-certificate",
        text: this.$t("navigation.TheThgAdminList.allThgs").toString(),
        action: this.goToThgFromThisVehicle,
        disabled: false
      },
      {
        icon: "mdi-account",
        text: this.$t("common.nouns.customer").toString(),
        action: this.goToUserProfile,
        disabled: false
      }
    ];
  }
  goToThgFromThisVehicle(vehicle: IThgVehicle): void {
    ThgPaginationModule.setFilter([
      new PageFilterElement({
        key: "vehicleId",
        operation: "$eq",
        value: vehicle.id
      })
    ]);

    const goToHelper = new GoToHelper(this.$router);

    goToHelper.goToThgAdminList();
  }

  goToUserProfile(vehicle: IThgVehicle): void {
    const goToHelper = new GoToHelper(this.$router);

    goToHelper.goToUserDetail(vehicle.userId);
  }

  openDecommissionDialog() {
    (this.$refs.decommission as ThgVehicleDecommissionDialog)?.open();
  }

  openIsAutoRenewalDialog() {
    (this.$refs.autorenewal as ThgVehicleIsAutoRenewalDialog)?.open();
  }

  openStatusDialog() {
    (this.$refs.status as ThgVehicleStatusDialog)?.open();
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: $t("objects.thgVehicle.created"),
      value: "timestamp.created",
      width: "120px",
      align: "start"
    });

    headers.push({
      text: $t("objects.thgVehicle.vehicleState"),
      value: "vehicleState",
      align: "start"
    });

    headers.push({
      text: $t("objects.thgVehicle.identificationnumber"),
      value: "identificationnumber",
      align: "start"
    });

    headers.push({
      text: $t("objects.thgVehicle.userId"),
      value: "userId",
      align: "start"
    });

    headers.push({
      text: $t("objects.thgVehicle.decommissioningDate"),
      value: "decommissioningDate",
      align: "start"
    });

    headers.push({
      text: $t("objects.thgVehicle.isAutoRenewal"),
      value: "isAutoRenewal",
      align: "start"
    });

    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get predefinedFilter() {
    const predefinedFilter = [
      {
        name: "Abonnement aktiviert",
        filter: [{ key: "isAutoRenewal", operation: "$eq", value: true }]
      }
    ];

    return predefinedFilter;
  }
}
