


















































import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { ThgChargingStationViewModelGen, ThgUserBaseViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    TableWrapper
  }
})
export default class ThgAnalyticsMeterReadingTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  thgStatusMap = thgStatusMap;

  get thgCustomerData() {
    return ThgAnalyticsModule.thgCustomerData.data;
  }

  get meterReadings() {
    return ThgAnalyticsModule.thgCustomerData.meterReading.slice().reverse();
  }

  get meterReadingTableHeaders(): ITableWrapperHeader[] {
    return [
      { text: "Status", value: "status", type: "string" },
      { text: "Antragsdatum", value: "timestamp.created", type: "date" },
      { text: "Kunde", value: "userId", type: "string" },
      { text: "Ladestation", value: "meterReading.chargingStationId", type: "string" },
      { text: "Startdatum", value: "meterReading.startDate", type: "date" },
      { text: "Enddatum", value: "meterReading.endDate", type: "date" },
      { text: "Menge in KWh", value: "meterReading.amountInKwh", type: "number" }
    ];
  }

  user(id: string): ThgUserBaseViewModelGen | undefined {
    const foundUser = ThgAnalyticsModule.thgCustomerData.data.find(entry => entry.user.id === id);
    if (foundUser) {
      return foundUser.user;
    }
  }

  chargingStation(id: string): ThgChargingStationViewModelGen | undefined {
    const chargingStation = ThgAnalyticsModule.thgCustomerData.chargingStation.find(entry => entry.id === id);
    if (chargingStation) {
      return chargingStation;
    }
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }
}
