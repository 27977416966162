










import { IFormableClass } from "@/lib/formable";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "./SideCard.vue";
import UpdateForm from "./UpdateForm.vue";

@Component({
  components: {
    SideCard,
    UpdateForm
  },
  filters: {}
})
export default class UpdateSideCard<T> extends Vue {
  @Prop()
  value!: T;

  @Prop()
  formable!: { new (value: T): UpdateDto<T> } & IFormableClass;

  @Prop()
  partnerId!: string;
}
