









































































import { IBillingDocuments } from "@/lib/utility/billing-data-loader";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ThgBillingCancellationDialog from "./ThgBillingCancellationDialog.vue";
import ThgBillingRecreationDialog from "./ThgBillingRecreationDialog.vue";
import ThgBillingDetailDataCard from "./ThgBillingDetailDataCard.vue";
import ThgBillingDetailResultsCard from "./ThgBillingDetailResultsCard.vue";
import { ThgPartnerCommissionPerYearDtoGen } from "@/services/thg/v1/data-contracts";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";

@Component({
  components: {
    LatestEntriesCardEmpty,
    ThgBillingDetailResultsCard,
    ThgBillingDetailDataCard,
    ThgBillingCancellationDialog,
    ThgBillingRecreationDialog
  }
})
export default class ThgBillingNumberDetail extends DarkModeHighlightMixin {
  @Prop()
  billingDocuments!: IBillingDocuments;

  commissions: ThgPartnerCommissionPerYearDtoGen[] = [];

  mounted() {
    this.setCommissions();
  }

  /**
   * Parse years out of accounting statements
   */
  @Watch("billingDocuments", { deep: true })
  setCommissions() {
    if (this.billingDocuments.billing?.type === BillingTypeEnum.CREDIT_PARTNER) {
      let years: number[] = [];
      for (const accountRecord of this.billingDocuments.billing?.accountRecord || []) {
        const found = accountRecord.bookingText.match(/20[0-9][0-9]/);
        let year: number;
        if (found?.length) {
          year = Number(found[0]);
          years.push(year);
        }
      }

      years = [...new Set(years)].sort();
      if (!years.length) {
        years = [2020, 2021, 2022, 2023, 2024, 2025, 2026];
      }

      this.commissions.splice(0);
      // if parsing did not work add a fixed list of years
      for (const year of years) {
        this.commissions.push({
          year,
          partnerCommission: 0
        });
      }
    }
  }

  pricePerKwH = 0;

  tab = 0;

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get displayToDetailView() {
    return !this.$route.params.billingNumber;
  }

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  get smallWidth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "width: 100%";
    }
    return "";
  }

  toDetailView() {
    this.$router.push({ path: `/billing/${this.billingDocuments.billing?.billingNumber}` });
  }

  get billingDate() {
    if (this.billingDocuments.billing?.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString") || "de-de";
      return detailedDate(this.billingDocuments.billing?.timestamp?.created.toString(), locale.toString());
    }
    return "";
  }
}
