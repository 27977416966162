var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-card",
        {
          attrs: {
            loading: _vm.loading,
            flat: "",
            outlined: "",
            tile: ""
          }
        },
        [
          _c(
            "template",
            {
              slot: "progress"
            },
            [
              _c("v-progress-linear", {
                attrs: {
                  color: _vm.loadingColor,
                  height: "3",
                  indeterminate: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-img",
            {
              staticClass: "white--text align-end",
              attrs: {
                src: _vm.headerUrl,
                gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                height: "200px"
              }
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "white--text"
                    },
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "text-h5 mb-1"
                        },
                        [_vm._v(" " + _vm._s(_vm.title) + " ")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "white--text"
                        },
                        [_vm._v(_vm._s(_vm.url))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-avatar",
                    {
                      staticClass: "hidden-xs-only",
                      attrs: {
                        tile: "",
                        size: "120",
                        color: "white"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          ref: "download",
                          attrs: {
                            download: _vm.qrCodeName
                          },
                          on: {
                            click: _vm.downloadQrLink
                          }
                        },
                        [
                          _c("qrcode-vue", {
                            ref: "qrcode",
                            attrs: {
                              value: _vm.url,
                              size: "120",
                              level: "L",
                              "data-test-qrcode": "",
                              id: "qr",
                              background: "transparent"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.THG,
                        field: _vm.partner.id
                      }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            right: "",
                            "nudge-right": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "primary"
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.$router.push({
                                              name: "ThgQuoteCreateView",
                                              params: {
                                                partnerId: _vm.partner.id
                                              }
                                            })
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgQuoteCreateView.title"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Neue THG-Quote anlegen")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS) &&
      _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
        ? _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-0",
                  attrs: {
                    fluid: ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "justify-sm": "center",
                        "justify-md": "space-between",
                        "justify-xl": "space-between",
                        "justify-lg": "space-between"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0",
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "4",
                            lg: "4",
                            xl: "4",
                            col: "12",
                            justify: "right",
                            align: "left"
                          }
                        },
                        [_c("partner-billing-information-dialog")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "4",
                            lg: "4",
                            xl: "4",
                            col: "12"
                          }
                        },
                        [
                          !_vm.loading
                            ? _c("analytics-query-filter", {
                                attrs: {
                                  labelText: _vm.$t(
                                    "report.thgDashboard.yearFilterLabel"
                                  ),
                                  items: _vm.years,
                                  field: "year"
                                },
                                on: {
                                  change: _vm.onYearChange
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "4",
                            lg: "4",
                            xl: "4",
                            col: "12",
                            justify: "right",
                            align: "right"
                          }
                        },
                        [
                          _c("analytics-date-range-selector-dialog", {
                            staticStyle: {
                              width: "100%"
                            },
                            attrs: {
                              dates: _vm.dates
                            },
                            on: {
                              setDate: _vm.setDateRange
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "4",
                        cols: "12"
                      }
                    },
                    [
                      _c("time-series-line-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.realTimeData.isLoading,
                          kpiGroup: _vm.realTimeTrendKpiGroup,
                          data: _vm.realTimeAnalysisData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.realtimeAnalysis"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "8",
                        md: "8",
                        cols: "12"
                      }
                    },
                    [
                      _c("time-series-line-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.trendAnalysis.isLoading,
                          kpiGroup: _vm.dayTrendKpiGroup,
                          data: _vm.dayTrendData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.daytrendAnalysis"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "6",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("time-series-line-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.trendAnalysis.isLoading,
                          data: _vm.trendData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.trendAnalysis"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "6",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("time-series-line-chart-card", {
                        attrs: {
                          loading:
                            _vm.dataHandler.meaterReadingTrendAnalysis
                              .isLoading,
                          data: _vm.meaterReadingTrendData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.meterReadingTrendAnalysis",
                          yAxixFactor: 1000,
                          yAxixAppendix: " MWh"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("pie-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.yearAnalysisData.isLoading,
                          data: _vm.yearDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.yearDistribution",
                          reverseAxis: true,
                          sort: false
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("pie-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.yearAnalysisData.isLoading,
                          data: _vm.isMultiYearDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.isMultiYearDistribution",
                          reverseAxis: true,
                          sort: false
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("bar-chart-card", {
                        attrs: {
                          loading:
                            _vm.dataHandler.weekdayDistribution.isLoading,
                          data: _vm.weekdayDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.weekdayDistribution",
                          reverseAxis: true,
                          sort: false
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("bar-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.hourDistribution.isLoading,
                          data: _vm.hourDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.hourDistribution",
                          reverseAxis: true,
                          sort: false,
                          showMaxItems: 24
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("pie-chart-card", {
                        attrs: {
                          loading:
                            _vm.dataHandler.vehicleClassDistribution.isLoading,
                          data: _vm.vehicleClassDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.vehicleClassDistribution"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("pie-chart-card", {
                        attrs: {
                          loading:
                            _vm.dataHandler.impactTypeDistribution.isLoading,
                          data: _vm.impactTypeDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.impactTypeDistribution"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("pie-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.statusDistribution.isLoading,
                          data: _vm.statusDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.statusTypeDistribution"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("bar-chart-card", {
                        attrs: {
                          loading:
                            _vm.dataHandler.affiliateDistribution.isLoading,
                          data: _vm.affiliateDistributionData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.topAffiliates"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        lg: "4",
                        md: "6",
                        cols: "12"
                      }
                    },
                    [
                      _c("table-data-card", {
                        attrs: {
                          loading: _vm.dataHandler.vinAnalysisData.isLoading,
                          data: _vm.vinAnalysisData,
                          title:
                            "views.thgPortal.ThgAnalytics.title.vinAnalysis"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }