var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t(
          "components.thg.ThgQuoteDetailInformationContractCard.title"
        ),
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c("thg-admin-update-payout-configuration-dialog", {
                attrs: {
                  loading: _vm.loading,
                  thg: _vm.thg
                },
                on: {
                  save: _vm.save
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-text",
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.thg.ThgQuoteDetailInformationContractCard.year",
                  {
                    year: _vm.thg.year
                  }
                )
              ) +
              " "
          ),
          _vm.thg.isMultiyear
            ? _c(
                "v-icon",
                {
                  attrs: {
                    small: ""
                  }
                },
                [_vm._v("mdi-numeric-2-box-multiple")]
              )
            : _c(
                "v-icon",
                {
                  attrs: {
                    small: ""
                  }
                },
                [_vm._v("mdi-numeric-1-box-multiple")]
              ),
          _c("br"),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.thg.ThgQuoteDetailInformationContractCard.payout",
                  {
                    payout: _vm.payout
                  }
                )
              ) +
              " "
          ),
          _vm.thg.payoutConfiguration
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.thg.ThgQuoteDetailInformationContractCard.per"
                      )
                    ) +
                    " " +
                    _vm._s(_vm.thg.payoutConfiguration.quantity) +
                    " " +
                    _vm._s(_vm.thg.payoutConfiguration.unit) +
                    " "
                ),
                _c(
                  "span",
                  [
                    _vm.calculatedPayout
                      ? _c(
                          "v-menu",
                          {
                            attrs: {
                              "open-on-hover": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function fn(_ref) {
                                    var on = _ref.on,
                                      attrs = _ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                "margin-top": "-1px",
                                                "margin-bottom": "1px"
                                              },
                                              attrs: {
                                                icon: "",
                                                small: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: ""
                                              }
                                            },
                                            [_vm._v("mdi-information")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3328749705
                            )
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-text", [
                                  _vm._v(
                                    " " + _vm._s(_vm.calculatedPayout) + " "
                                  )
                                ]),
                                _c("v-card-text", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.reverseChargeProcedureText) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("br"),
          _vm.thg.promotionConfiguration
            ? _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.thg.ThgQuoteDetailInformationContractCard.promotion",
                        {
                          promotion: _vm.promotion
                        }
                      )
                    ) +
                    " "
                ),
                _c("br")
              ])
            : _vm._e(),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.thg.ThgQuoteDetailInformationContractCard.partner",
                  {
                    partnerId: _vm.thg.partnerId
                  }
                )
              ) +
              " "
          ),
          _c(
            "v-btn",
            {
              attrs: {
                "x-small": "",
                text: ""
              },
              on: {
                click: _vm.toPartnerDetailView
              }
            },
            [
              _c(
                "v-icon",
                {
                  attrs: {
                    "x-small": ""
                  }
                },
                [_vm._v("mdi-open-in-new")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }