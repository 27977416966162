

































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PartnerRegistrationCard from "@/components/partner/PartnerRegistrationCard.vue";
import ThgQuoteDetailImageCard from "@/components/thg/ThgQuoteDetailImageCard.vue";
import ThgQuoteDetailInformationContractCard from "@/components/thg/ThgQuoteDetailInformationContractCard.vue";
import ThgQuoteDetailInformationImpactFactorCard from "@/components/thg/ThgQuoteDetailInformationImpactFactorCard.vue";
import ThgQuoteDetailInformationStatusCard from "@/components/thg/ThgQuoteDetailInformationStatusCard.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { HttpException } from "@/lib/exceptions/http";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ActivityLog } from "@/models/activity-log.entity";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IThg } from "@/models/thg.entity";
import { IUser } from "@/models/user.entity";
import { ThgRegistrationDtoGen, ThgUpdateThgAdminDtoGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogDataAccessLayer } from "@/store/modules/access-layers/activity-log-service.access-layer";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { UserModule } from "@/store/modules/me-user.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop } from "vue-property-decorator";
import ThgQuoteCountryCodeCard from "./ThgQuoteCountryCodeCard.vue";
import ThgQuoteDetailInformationProofCard from "./ThgQuoteDetailInformationProofCard.vue";
import ThgQuoteDetailInformationSavingCard from "./ThgQuoteDetailInformationSavingCard.vue";
import ThgQuoteDetailInformationVehicleCard from "./ThgQuoteDetailInformationVehicleCard.vue";

@Component({
  components: {
    CustomerContactCard,
    ThgQuoteDetailInformationImpactFactorCard,
    PartnerRegistrationCard,
    ThgQuoteDetailInformationStatusCard,
    ThgQuoteCountryCodeCard,
    ThgQuoteDetailImageCard,
    ThgQuoteDetailInformationContractCard,
    ThgQuoteDetailInformationSavingCard,
    ThgQuoteDetailInformationProofCard,
    ThgQuoteDetailInformationVehicleCard
  }
})
export default class ThgQuoteDetailInformationCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedThg!: IThg;

  @Prop()
  selectedUser!: IUser;

  /**
   * Internal loading state
   */
  isLoading = false;

  registrationUpdateLoading = false;

  async updateStatusCb(status: string, userNotification: boolean) {
    await ThgPortalModule.updateSelectedThgStatus({
      dto: { status: status as any },
      noUserNotification: !userNotification
    });

    // Activity is created in backend. Shift it locally
    const log = new ActivityLog({
      id: this.selectedThg.id,
      user: UserModule.user,
      source: {
        refType: ResourceEnum.THG,
        refId: this.selectedThg.id
      },
      actionType: ActionEnum.UPDATE,
      activity: ActivityTypeEnum.UPDATE_PROGRESS_STATUS,
      comment: status,
      timestamp: { created: new Date().toISOString(), lastModified: new Date().toISOString(), modified: [] },
      partnerId: this.selectedThg.partnerId
    });
    ActivityLogDataAccessLayer.set(log);
  }

  async updateCountryCodeCb(countryCode: CountryCodeEnum) {
    await ThgPortalModule.updateThg({
      countryCode: countryCode
    });
  }

  async savePayoutConfiguration(updatedThg: IThg) {
    this.isLoading = true;
    try {
      const updateThgDto: ThgUpdateThgAdminDtoGen = {
        numberplate: this.selectedThg.numberplate,
        impactType: this.selectedThg.impactType,
        impactFactor: this.selectedThg.impactFactor,
        code: this.selectedThg.code,
        payoutConfiguration: updatedThg.payoutConfiguration,
        meterReading: updatedThg.meterReading,
        year: updatedThg.year,
        promotionConfiguration: updatedThg.promotionConfiguration
      };
      await ThgPortalModule.updateThg(updateThgDto);
      this.$toast.success("👍");
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.isLoading = false;
    }
  }

  get registrationImages() {
    return this.selectedThg.registrationImages;
  }

  get fullname() {
    if (!this.selectedUser?.firstName && !this.selectedUser?.lastName) return "";

    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  get registrationImageFront(): string {
    const item = this.selectedThg.registrationImages.filter(
      item => item.type === (ReportImageType.registration as any)
    );
    if (item.length !== 0) {
      return item[0].url || "";
    }
    return "";
  }

  async updateRegistration(registration: ThgRegistrationDtoGen) {
    try {
      this.registrationUpdateLoading = true;
      await ThgPortalModule.updateRegistration(registration);
      if (registration.numberplate) {
        const updateThgDto: ThgUpdateThgAdminDtoGen = {
          numberplate: registration.numberplate,
          impactType: this.selectedThg.impactType,
          impactFactor: this.selectedThg.impactFactor,
          code: this.selectedThg.code
        };
        await ThgPortalModule.updateThg(updateThgDto);
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Update fehlgeschlagen.");
    } finally {
      this.registrationUpdateLoading = false;
    }
  }
}
