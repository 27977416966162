var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.loading
    ? _c("LoaderPortal")
    : _c("router-view", {
        key: _vm.$route.fullPath
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }