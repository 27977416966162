



























































































import { Component, Vue, Prop } from "vue-property-decorator";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import { IBilling } from "@/models/billing.entity";
import { $t } from "@/lib/utility/t";
import ActivityTimeLineItemDocumentCardImages from "../partner/ActivityTimeLineItemDocumentCardImages.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import RefsUser from "../utility/RefsUser.vue";
import RefsPartner from "../utility/RefsPartner.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import FileDownload from "../utility/FileDownload.vue";
import { IAdminUser } from "@/models/user.entity";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: { MHeader, ActivityTimeLineItemDocumentCardImages, TableWrapper, RefsUser, RefsPartner, FileDownload },
  filters: { getFlagEmoji }
})
export default class ThgBillingCard extends Vue {
  @Prop()
  value!: IBilling;

  @Prop()
  isUserLoading!: boolean;

  @Prop()
  isPartnerLoading!: boolean;

  @Prop()
  partner?: MrfiktivPartnerViewModelGen;

  @Prop()
  user?: IAdminUser;

  readonly DETAIL = "detail";

  get chips(): IAction[] {
    const chips: IAction[] = [
      {
        text: $t("components.thg.thgBillingCreateNewBillingManual." + this.value.type),
        key: "type"
      }
    ];

    if (this.value.isPublished) {
      chips.push({
        text: $t("objects.billing.isPublished"),
        key: "published",
        color: "info"
      });
    }

    return chips;
  }

  get breadCrumbs(): IBreadcrumb[] | undefined {
    return undefined;
  }

  get alerts() {
    return undefined;
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("project.ticket.actions.openInNewTab"),
        key: this.DETAIL,
        icon: "mdi-open-in-new"
      }
    ];
  }

  get pdf() {
    if (!this.value.pdfBilling?.length) return undefined;

    return { title: this.value.pdfBilling[0].name, url: this.value.pdfBilling[0].url };
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push(
      { text: $t("components.thg.thgBillingCsvSelection.amount"), value: "amount", align: "end", sortable: false },
      { text: $t("components.thg.thgBillingCsvSelection.bookingText"), value: "bookingText", sortable: false },
      { text: $t("components.thg.thgBillingCsvSelection.account"), value: "account", sortable: false },
      { text: $t("components.thg.thgBillingCsvSelection.contraAccount"), value: "contraAccount", sortable: false },
      { text: "", value: "debitOrCredit", sortable: false }
    );

    return headers;
  }

  get accountRecords() {
    if (!this.value.accountRecord?.length) return [];

    const total = this.value.accountRecordTotal;

    return [
      ...this.value.accountRecord,
      {
        amount: total,
        debitOrCredit: "",
        account: "",
        contraAccount: "",
        bookingText: "Total"
      }
    ];
  }

  get createdDate() {
    return simpleDoubleDigitDate(this.value.timestamp.created);
  }

  get isLeftColumn() {
    return this.pdf || this.accountRecords.length;
  }

  get isRightColumn() {
    return (
      this.isUserLoading ||
      this.user ||
      this.isPartnerLoading ||
      this.partner ||
      this.value.referenceIds?.length ||
      this.value.partnerId
    );
  }

  processAction(action: IAction) {
    switch (action.key) {
      case this.DETAIL:
        this.goToBilling();
        break;
    }
  }

  download() {
    (this.$refs.download as FileDownload).download();
  }

  goToThgDetail(partnerId: string, thgId: string) {
    new GoToHelper(this.$router).goToThgDetail(partnerId, thgId, true);
  }

  goToBilling() {
    new GoToHelper(this.$router).goToBillingDetail(this.value.billingNumber.toString(), undefined, true);
  }

  goToUserDetail() {
    if (!this.value.userId) return;
    new GoToHelper(this.$router).goToUserDetail(this.value.userId, true);
  }

  goToPartnerDetail() {
    if (!this.value.partnerId) return;
    new GoToHelper(this.$router).goToPartnerDetail(this.value.partnerId, true);
  }
}
