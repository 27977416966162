var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.isDialogActive,
        title: _vm.$t("components.ThgVehicleDecommissionDialog.title"),
        subtitle: _vm.subtitle,
        rightLoading: _vm.isLoading,
        leftDisabled: _vm.isLoading,
        rightDisabled: !_vm.valid,
        persistent: true,
        supressKeyboardConfirm: true
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        leftClick: function leftClick($event) {
          _vm.isDialogActive = false
        },
        rightClick: _vm.update
      }
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-checkbox",
            {
              attrs: {
                disabled: _vm.isLoading,
                label: _vm.$t(
                  "components.ThgVehicleDecommissionDialog.checkboxLabel"
                )
              },
              model: {
                value: _vm.isDecommissioned,
                callback: function callback($$v) {
                  _vm.isDecommissioned = $$v
                },
                expression: "isDecommissioned"
              }
            },
            [_vm._v(" ? ")]
          ),
          _vm.isDecommissioned
            ? _c("v-text-field", {
                staticClass: "mt-6 mb-n4",
                attrs: {
                  type: "date",
                  label: _vm.$t(
                    "components.ThgVehicleDecommissionDialog.dateLabel"
                  ),
                  rules: _vm.requiredRule,
                  disabled: _vm.isLoading,
                  outlined: "",
                  dense: "",
                  required: ""
                },
                model: {
                  value: _vm.decommissioningDate,
                  callback: function callback($$v) {
                    _vm.decommissioningDate = $$v
                  },
                  expression: "decommissioningDate"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isLoading
        ? _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "components.ThgVehicleDecommissionDialog.loadingText",
                    {
                      counter: _vm.counter,
                      total: _vm.vehicles.length
                    }
                  )
                ) +
                " "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }