







import LoaderPortal from "@/components/utility/LoaderPortal.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { EventBusEnum } from "@/lib/enum/eventBus.enum";
import eventBus from "@/lib/eventBus";
import { AppColorSchema } from "@/lib/utility/AppColorSchema";
import { IHasReset } from "@/lib/utility/data/store.interface";
import documentService from "@/services/sign/services/document.service";
import signRequestService from "@/services/sign/services/sign-request.service";
import signService from "@/services/sign/services/sign.service";
import { ThgSignHttpClientProvider } from "@/services/sign/sign-http-client.provider";
import { AllMessagesModule } from "@/store/modules/all-messages.store";
import { ConfigModule } from "@/store/modules/config";
import { CredentialModule } from "@/store/modules/credential.store";
import { InboxModule } from "@/store/modules/inbox-message.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Vue } from "vue-property-decorator";
import { PartnerModule } from "../store/modules/partner";
import { UiModule } from "../store/modules/ui.store";

@Component({ components: { LoaderPortal } })
export default class V extends Vue {
  created() {
    ConfigModule.setIsPartnerBankingAvailable(true);
    ConfigModule.setAppContext(AppContextEnum.THG_PORTAL);
    new AppColorSchema(this.$vuetify, ConfigModule.mintFutureColorSchema);
    ConfigModule.setNavigationSettings({
      showMmmmintLogo: false,
      showThePartnerList: false,
      showTheCustomerList: false,
      showTheOrganizationList: false,
      showTheFleetList: false,
      showTheAnonymizer: false,
      showTheOnlineBookingList: false,
      showTheAdminList: false,
      showTheThgAdminList: true,
      showTheAboutList: true,
      showTheThgProcessingList: true,
      showTheThgPartnerList: true,
      showThgFeatureSettings: true,
      showTheDigitalSignatureList: true,
      showTheProjectList: false,
      showTheCostList: false
    });
    ConfigModule.setThgBaseUrl();
    ConfigModule.setPartnerSelectorDestinationVue("ThgDashboardPartnerSelected");
    ConfigModule.setPortalLogo(AssetEnum.wirkaufendeinethg);
    ConfigModule.setThgSupportMail();

    signService.setProxy(new ThgSignHttpClientProvider());
    signRequestService.setProxy(new ThgSignHttpClientProvider());
    documentService.setProxy(new ThgSignHttpClientProvider());
  }

  async mounted() {
    eventBus.$on("logout", () => {
      this.$log.info("Handle Logout Event");
      PartnerModule.resetPartners();
      this.$router.push({ name: "Logout" });
    });
    eventBus.$on("sessionExpired", () => {
      //handle forced logout
      this.$router.push({ path: "/login/logout?reason=expired" });
    });
    eventBus.$on(EventBusEnum.SPAM_USER_EXCEPTION, async () => {
      this.$router.push({ name: "NoRoles" });
    });

    eventBus.$on("partnerLoaded", () => {
      const storesToReset: IHasReset[] = [
        ThgPortalModule,
        PartnerUserModule,
        CredentialModule,
        AllMessagesModule,
        InboxModule
      ];
      storesToReset.forEach(s => s.reset());
    });

    try {
      await UserModule.getUserInfo();

      // Load all partners && set the first to be default
      await PartnerModule.getPartners();

      /**
       * Set the default partner. On admin select a predefined if found.
       */
      if (PartnerModule.partners.length !== 0) {
        let activePartner = PartnerModule.partners[0];
        if (UserModule.isAdmin) {
          activePartner =
            PartnerModule.partners.find(p => p._id === ConfigModule.preferredAdminThgPartner) ||
            PartnerModule.partners[0];
        }
        PartnerModule.setPartner(activePartner);
      }

      if (UserModule.isAdmin) {
        const promises: Promise<any>[] = [];

        promises.push(ThgPortalModule.getDuplicateThg());
        promises.push(ThgPortalModule.getMeterReadings());
        promises.push(ThgAnalyticsModule.getStatusDistributionData());
        await Promise.all(promises);
      }
    } catch (error) {
      this.$log.error("Error on ThgPortal loading");
      this.$log.warn(error);
    } finally {
      UiModule.setLoading(false);
    }
  }

  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off("logout");
    eventBus.$off("sessionExpired");
  }

  get loading() {
    return UiModule.loading;
  }
}
