

























import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog }
})
export default class ThgBillingAccountgManuellSelectionDialog extends DarkModeHighlightMixin {
  dialog = false;
  billingnumberUnparsed = "";
  parsed: number[] = [];

  isFormValid = false;

  get required() {
    return [requiredRule()];
  }

  initialize() {
    this.dialog = true;
    this.billingnumberUnparsed = "";
    this.parsed = [];
  }

  /**
   * Parses all vins out of list
   */
  parse() {
    this.parsed = this.parseNumberList(this.billingnumberUnparsed);
  }

  processSelection() {
    this.$emit("processSelection", this.parsed);
    this.dialog = false;
  }

  rightClick() {
    if (this.parsed.length) {
      this.processSelection();
    }
    this.parse();
  }

  close() {
    this.dialog = false;
  }

  /**
   *
   */
  parseNumberList(input: string): number[] {
    return input
      .trim() // Entfernt Leerzeichen am Anfang und Ende
      .split(/\s+/) // Teilt den String an einer oder mehreren Leerzeichen
      .map(numStr => parseInt(numStr, 10)) // Wandelt jeden Teil in eine Zahl um
      .filter(num => !isNaN(num)); // Entfernt eventuelle NaN-Werte
  }
}
