




















































import FilterCard from "@/components/filter/FilterCard.vue";
import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { MeterReadingPaginatedModule } from "@/store/modules/meter-reading-paginated.store";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { IThgMeterReading } from "@/models/thg-meter-reading.entity";

@Component({ components: { TheLayoutPortalList, ThgQuoteExportDialog, FilterCard } })
export default class ThgMeterReadingListCardPaginated extends mixins(
  PaginationListMixin,
  DarkModeHighlightMixin,
  PermissionMixin
) {
  loading = false;

  isLoadingDetail = false;

  dateFilter = getDateFilterList("timestamp");

  getModule() {
    return MeterReadingPaginatedModule;
  }

  /**
   * The available categories visible in the filter menu
   */
  get filterList(): IFilterListElement[] {
    return [...this.dateFilter];
  }

  get items(): ThgThgMeterReadingViewModelGen[] {
    return this.getModule().paginationList;
  }

  get isSmallScreen() {
    return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
  }

  async mounted() {
    this.loading = true;
    //load from beginning
    await this.refresh();

    // Load first item
    if (this.items.length !== 0 && !this.isSmallScreen) {
      await this.set(this.items[0]);
    }

    this.loading = false;
  }

  async set(item: any) {
    this.setIsLoadingDetail(true);
    if (this.isSmallScreen) {
      this.$router.push({ name: "ThgMeterReadingDetailView", params: { meterReadingId: item.id } });
    } else {
      const meterReading = await ThgPortalModule.getSelectedMeterReading(item.id);
      if (!meterReading) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      const chargingStation = await ThgPortalModule.getSelectedChargingStation(item.meterReading.chargingStationId);
      const meterReadings = await ThgPortalModule.getMeterReadingForChargingStation(
        item.meterReading.chargingStationId
      );
      if (!chargingStation) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      if (!meterReadings) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      const user = await AdminUserPaginationModule.getSelectedUser(item.userId);
      if (!user) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noUserFound")));
      }
      this.$emit("user:update", user);
    }
    this.setIsLoadingDetail(false);
  }

  async refresh() {
    this.loading = true;
    await MeterReadingPaginatedModule.fetchFirstPage({});
    this.loading = false;
  }

  async loadAllFromLastId() {
    if (this.items.length === 0) {
      await this.getModule().fetchFirstPage({});
    } else {
      await this.getModule().fetchNextPage({});
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.loadAllFromLastId();
    } catch (e) {
      this.$toast.error((e as any).message);
    } finally {
      this.isLoadingMore = false;
    }
  }

  setIsLoadingDetail(status: boolean) {
    this.isLoadingDetail = status;
    this.$emit("isLoadingDetail", status);
  }

  thgStatusMap = thgStatusMap;
  isLoadingMore = false;

  date(item: any): string {
    return simpleDate(item?.timestamp?.created);
  }

  simpleDate(date: string): string {
    return simpleDate(date);
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "Alles im grünen Bereich.",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: thgStatusEnum,
        color: "error"
      }
    );
  }

  isActive(item: IThgMeterReading): "darken-4" | "lighten-4" | "" {
    return this.getModule().selectedThg.id === item?.id ? "darken-4" : "";
  }
}
