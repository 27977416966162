/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { UnitEnum } from "@/lib/enum/unit.enum";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgCreatePriceDtoGen, ThgPriceViewModelGen } from "@/services/thg/v1/data-contracts";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";

/**
 *
 */
export class CreatePriceDto implements ThgCreatePriceDtoGen {
  /**
   *The partner
   */
  partner?: PartnerEntity;

  /**
   * @inheritdoc
   */
  title?: string;

  /**
   * @inheritdoc
   */
  subtitle?: string;

  /**
   * @inheritdoc
   */
  description?: string;

  /**
   * @inheritdoc
   */
  hint?: string;

  /**
   * @inheritdoc
   */
  value: number;

  /**
   * @inheritdoc
   */
  commission: number;

  /**
   * @inheritdoc
   */
  isFixed: boolean;

  /**
   * When set overrides value
   */
  _manualValue?: number;

  /**
   * @inheritdoc
   */
  year: number;

  /**
   * @inheritdoc
   */
  currency: CurrencyUnitEnum;

  /**
   * @inheritdoc
   */
  unit: UnitEnum;

  /**
   * @inheritdoc
   */
  isActive?: boolean | undefined;

  constructor(price?: ThgPriceViewModelGen, partner?: PartnerEntity) {
    this.title = "";
    this.description = "";
    this.subtitle = "";
    this.hint = "";

    this.currency = CurrencyUnitEnum.EUR;
    this.unit = UnitEnum.MWH;

    this.year = new Date().getFullYear();

    this.value = 0;
    this.commission = 0;
    this.isFixed = false;
    this.isActive = false;

    if (partner) {
      this.partner = partner;
    }

    if (price) {
      this.title = price.title;
      this.description = price.description;
      this.subtitle = price.subtitle;
      this.hint = price.hint;

      this.currency = price.currency as CurrencyUnitEnum;
      this.unit = price.unit as UnitEnum;

      this.year = price.year;

      this.value = price.value;
      this.commission = price.commission;
      this.isFixed = price.isFixed;
      this.isActive = price.isActive;
    }
  }

  toDto(): ThgCreatePriceDtoGen {
    const dto: ThgCreatePriceDtoGen = {
      title: this.title,
      description: this.description,
      subtitle: this.subtitle,
      hint: this.hint,
      value: this.value,
      commission: this.commission,
      isFixed: this.isFixed,
      year: this.year,
      currency: this.currency,
      unit: this.unit,
      isActive: this.isActive
    };

    return dto;
  }
}
