


















































import FilterCard from "@/components/filter/FilterCard.vue";
import ThgBatchCreateDialog from "@/components/thg/ThgBatchCreateDialog.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBatchViewmodelItemGen } from "@/services/thg/v1/data-contracts";
import { BatchModule } from "@/store/modules/batch.store";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { ThgBatchCreateDialog, FilterCard } })
export default class BatchListCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  search = "";
  thgStatusMap = thgStatusMap;

  batchStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: this.$t("components.partner.PartnerReportList.undefinedStatus").toString(),
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: thgStatusEnum,
        color: "error"
      }
    );
  }
  batchTypeIcon(batch: ThgBatchViewmodelItemGen): string {
    if (batch.type === BatchTypeEnum.meterReading) {
      return "mdi-ev-station";
    }
    return "mdi-car-multiple";
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get batches() {
    return BatchModule.allBatches;
  }

  isActive(item: any) {
    return item.id === BatchModule.selectedBatch.batch.id ? `grey ${this.color}` : "";
  }

  date(item: any): string {
    return simpleDate(item.timestamp?.created);
  }

  isLoadingDetail(status: boolean) {
    this.$emit("isLoadingDetail", status);
  }

  async set(item: any) {
    this.isLoadingDetail(true);
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "ThgBatchDetail", params: { batchId: item.id } });
    } else {
      let thg = undefined;
      try {
        thg = await BatchModule.find(item.id);
      } catch (error) {
        this.$log.error(error);
        this.$toast.error(this.$t("components.thg.BatchListCard.errorBatchdetail").toString());
      }

      try {
        if (thg) {
          await BatchModule.getPartnerDistribution(item.id);
        }
      } catch (error) {
        this.$toast.error(this.$t("components.thg.BatchListCard.errorPartnerdistribution").toString());
        this.$log.error(error);
      }
    }
    this.isLoadingDetail(false);
  }

  //Filter:

  /**
   * The available categories visible in the filter menu
   */
  filterList = getDateFilterList("timestamp");

  items = this.batches;

  get filteredItems() {
    return this.items;
  }
}
