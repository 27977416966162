





















import { HttpException } from "@/lib/exceptions/http";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ThgAdminUpdateStatusDialog from "./ThgAdminUpdateStatusDialog.vue";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";

@Component({
  components: { Card, ThgAdminUpdateStatusDialog }
})
export default class ThgQuoteDetailInformationStatusCard extends DarkModeHighlightMixin {
  @Prop({ default: {} })
  thg!: IThg;

  @Prop()
  cb!: Function;

  loading = false;

  thgStatusMap = thgStatusMap;

  thgStatus(thgStatusEnum: ThgStatusEnum | string): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum as ThgStatusEnum) || {
        text: "Alles im grünen Bereich.",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: thgStatusEnum,
        color: "error"
      }
    );
  }

  async save(status: string, userNotification: boolean) {
    this.loading = true;
    try {
      await this.cb(status, userNotification);
      this.$toast.success("👍");
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      } else {
        this.$toast.error("Status konnte nicht geändert werden");
      }
    } finally {
      this.loading = false;
    }
  }
}
