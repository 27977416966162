var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-list", {
    attrs: {
      title: _vm.$t("navigation.TheThgAdminList.thg"),
      filterList: _vm.filterList,
      loading: _vm.loading,
      isLoadingMore: _vm.isLoadingMore,
      getModule: _vm.getModule
    },
    on: {
      loadMore: _vm.loadMore,
      refresh: _vm.refresh
    },
    scopedSlots: _vm._u([
      {
        key: "options",
        fn: function fn() {
          return [
            _c("thg-quote-export-dialog", {
              attrs: {
                thgs: _vm.quotes
              }
            }),
            _c(
              "Can",
              {
                attrs: {
                  I: _vm.ActionEnum.CREATE,
                  a: _vm.ResourceEnum.THG,
                  field: _vm.partnerId
                }
              },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      bottom: "",
                      "nudge-right": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function fn(_ref) {
                          var on = _ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    icon: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.$router.push({
                                        name: "ThgQuoteCreateView"
                                      })
                                    }
                                  }
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v("Neue THG-Quote anlegen")])]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "listItem",
        fn: function fn(_ref2) {
          var listItem = _ref2.listItem
          return [
            _c(
              "v-list-item",
              {
                key: "thglistelement" + listItem.id,
                class: _vm.isSelected(listItem),
                attrs: {
                  "two-line": ""
                },
                on: {
                  click: function click($event) {
                    return _vm.set(listItem)
                  }
                }
              },
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(
                        _vm._s(listItem.numberplate) +
                          " (" +
                          _vm._s(listItem.year) +
                          ")"
                      )
                    ]),
                    _c("v-list-item-subtitle", {
                      domProps: {
                        innerHTML: _vm._s(listItem.impactType)
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-list-item-action",
                  [
                    _c("v-list-item-action-text", [
                      _vm._v(_vm._s(_vm.date(listItem)))
                    ]),
                    _c(
                      "span",
                      [
                        listItem.decommissioningDate
                          ? _c(
                              "v-chip",
                              {
                                attrs: {
                                  color: "error",
                                  "x-small": ""
                                }
                              },
                              [_vm._v(" Decommissioned ")]
                            )
                          : _vm._e(),
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              color: "grey lighten-1"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.thgStatus(listItem.status).icon)
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }