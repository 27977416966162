var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.address")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.name")
                },
                model: {
                  value: _vm.value.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: _vm.$t("objects.pdfInput.nameLine2")
                },
                model: {
                  value: _vm.value.nameLine2,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "nameLine2", $$v)
                  },
                  expression: "value.nameLine2"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.address.street")
                },
                model: {
                  value: _vm.value.address.street,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.address, "street", $$v)
                  },
                  expression: "value.address.street"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.address.zip")
                },
                model: {
                  value: _vm.value.address.zip,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.address, "zip", $$v)
                  },
                  expression: "value.address.zip"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.address.city")
                },
                model: {
                  value: _vm.value.address.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.address, "city", $$v)
                  },
                  expression: "value.address.city"
                }
              })
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.headerData")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  readonly: "",
                  label: _vm.$t("objects.pdfInput.billingNumber")
                },
                model: {
                  value: _vm.value.billingNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "billingNumber", $$v)
                  },
                  expression: "value.billingNumber"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  type: "number",
                  label: _vm.$t("objects.pdfInput.cancellationNumber")
                },
                model: {
                  value: _vm.value.cancellationNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "cancellationNumber", $$v)
                  },
                  expression: "value.cancellationNumber"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: _vm.$t("objects.pdfInput.userName")
                },
                model: {
                  value: _vm.value.userName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "userName", $$v)
                  },
                  expression: "value.userName"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  type: "number",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.accountNumber")
                },
                model: {
                  value: _vm.value.accountNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "accountNumber", $$v)
                  },
                  expression: "value.accountNumber"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.iban")
                },
                model: {
                  value: _vm.value.iban,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "iban", $$v)
                  },
                  expression: "value.iban"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.bankName")
                },
                model: {
                  value: _vm.value.bankName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "bankName", $$v)
                  },
                  expression: "value.bankName"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: _vm.$t("objects.pdfInput.taxnumber")
                },
                model: {
                  value: _vm.value.taxnumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "taxnumber", $$v)
                  },
                  expression: "value.taxnumber"
                }
              })
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.positions")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _vm._l(_vm.value.billingItems, function(item, index) {
                return _c(
                  "div",
                  {
                    key: "billingItems" + index,
                    staticClass: "my-1"
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        type: "number",
                        rules: _vm.requiredRule,
                        label: _vm.$t("objects.pdfInput.billingItems.amount")
                      },
                      model: {
                        value: item.amount,
                        callback: function callback($$v) {
                          _vm.$set(item, "amount", $$v)
                        },
                        expression: "item.amount"
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        type: "number",
                        rules: _vm.requiredRule,
                        label: _vm.$t(
                          "objects.pdfInput.billingItems.product.pricePerProduct"
                        )
                      },
                      model: {
                        value: item.product.pricePerProduct,
                        callback: function callback($$v) {
                          _vm.$set(item.product, "pricePerProduct", $$v)
                        },
                        expression: "item.product.pricePerProduct"
                      }
                    }),
                    _c("v-select", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        items: [true, false],
                        label: _vm.$t("objects.pdfInput.billingItems.isVat")
                      },
                      model: {
                        value: item.isVat,
                        callback: function callback($$v) {
                          _vm.$set(item, "isVat", $$v)
                        },
                        expression: "item.isVat"
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        rules: _vm.requiredRule,
                        label: _vm.$t(
                          "objects.pdfInput.billingItems.product.name"
                        )
                      },
                      model: {
                        value: item.product.name,
                        callback: function callback($$v) {
                          _vm.$set(item.product, "name", $$v)
                        },
                        expression: "item.product.name"
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        readonly: "",
                        type: "number",
                        value: item.amount * item.product.pricePerProduct,
                        label: _vm.$t(
                          "objects.pdfInput.billingItems.totalPriceWithoutTax"
                        )
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        readonly: "",
                        type: "number",
                        value:
                          Math.round(
                            item.amount *
                              item.product.pricePerProduct *
                              (1 + 0.19 * item.isVat) *
                              100
                          ) / 100,
                        label: _vm.$t(
                          "objects.pdfInput.billingItems.totalPriceWithVat"
                        )
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        rules: _vm.requiredRule,
                        label: _vm.$t(
                          "objects.pdfInput.billingItems.product.description"
                        )
                      },
                      model: {
                        value: item.product.description,
                        callback: function callback($$v) {
                          _vm.$set(item.product, "description", $$v)
                        },
                        expression: "item.product.description"
                      }
                    }),
                    _c("v-select", {
                      staticClass: "mt-n2",
                      attrs: {
                        dense: "",
                        outlined: "",
                        items: [true, false],
                        label: _vm.$t(
                          "objects.pdfInput.billingItems.product.isDeductible"
                        )
                      },
                      model: {
                        value: item.product.isDeductible,
                        callback: function callback($$v) {
                          _vm.$set(item.product, "isDeductible", $$v)
                        },
                        expression: "item.product.isDeductible"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-n4 mb-4",
                        attrs: {
                          outlined: "",
                          small: "",
                          text: "",
                          block: ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.value.billingItems.splice(index)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]
                    )
                  ],
                  1
                )
              }),
              _c(
                "div",
                [
                  _c(
                    "v-sheet",
                    {
                      staticStyle: {
                        display: "flex",
                        width: "100%",
                        height: "100px"
                      },
                      attrs: {
                        outlined: "",
                        flat: ""
                      }
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            width: "100%",
                            height: "100%"
                          },
                          attrs: {
                            small: "",
                            text: ""
                          },
                          on: {
                            click: _vm.addBillingItem
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.appendix")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.bottomText")
                },
                model: {
                  value: _vm.value.bottomText,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "bottomText", $$v)
                  },
                  expression: "value.bottomText"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: _vm.$t("objects.pdfInput.appUrl")
                },
                model: {
                  value: _vm.value.appUrl,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "appUrl", $$v)
                  },
                  expression: "value.appUrl"
                }
              })
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.serviceDate")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  type: "date",
                  label: _vm.$t("objects.pdfInput.serviceDateFrom")
                },
                model: {
                  value: _vm.value.serviceDateFrom,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "serviceDateFrom", $$v)
                  },
                  expression: "value.serviceDateFrom"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  type: "date",
                  label: _vm.$t("objects.pdfInput.serviceDateTo")
                },
                model: {
                  value: _vm.value.serviceDateTo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "serviceDateTo", $$v)
                  },
                  expression: "value.serviceDateTo"
                }
              })
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.company")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.companyData.address.city")
                },
                model: {
                  value: _vm.value.companyData.address.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.address, "city", $$v)
                  },
                  expression: "value.companyData.address.city"
                }
              }),
              _c("v-select", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  items: _vm.CountryCodes,
                  label: _vm.$t(
                    "objects.pdfInput.companyData.address.countryCode"
                  )
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function fn(_ref2) {
                      var item = _ref2.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) +
                            " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.value.companyData.address.countryCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.address, "countryCode", $$v)
                  },
                  expression: "value.companyData.address.countryCode"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.companyData.address.state")
                },
                model: {
                  value: _vm.value.companyData.address.state,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.address, "state", $$v)
                  },
                  expression: "value.companyData.address.state"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.companyData.address.street")
                },
                model: {
                  value: _vm.value.companyData.address.street,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.address, "street", $$v)
                  },
                  expression: "value.companyData.address.street"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.companyData.address.zip")
                },
                model: {
                  value: _vm.value.companyData.address.zip,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.address, "zip", $$v)
                  },
                  expression: "value.companyData.address.zip"
                }
              })
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(" " + _vm._s(_vm.$t("common.nouns.contact")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.emailRule,
                  label: _vm.$t("objects.pdfInput.companyData.contact.email")
                },
                model: {
                  value: _vm.value.companyData.contact.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.contact, "email", $$v)
                  },
                  expression: "value.companyData.contact.email"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.companyData.contact.phone")
                },
                model: {
                  value: _vm.value.companyData.contact.phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData.contact, "phone", $$v)
                  },
                  expression: "value.companyData.contact.phone"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-n2",
                attrs: {
                  dense: "",
                  outlined: "",
                  rules: _vm.requiredRule,
                  label: _vm.$t("objects.pdfInput.companyData.name")
                },
                model: {
                  value: _vm.value.companyData.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value.companyData, "name", $$v)
                  },
                  expression: "value.companyData.name"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }