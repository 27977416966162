
































import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { ThgUserBaseViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { $t } from "@/lib/utility/t";

@Component({
  components: {
    TableWrapper
  }
})
export default class ThgAnalyticsVehicleTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  thgStatusMap = thgStatusMap;

  get thgCustomerData() {
    return ThgAnalyticsModule.thgCustomerData.data;
  }

  get thgs() {
    return ThgAnalyticsModule.thgCustomerData.thg.slice().reverse();
  }

  get meterReadingTableHeaders(): ITableWrapperHeader[] {
    return [
      { text: $t("common.nouns.status"), value: "status", type: "string", width: "100px" },
      { text: $t("common.nouns.applicationDate"), value: "timestamp.created", type: "date", width: "100px" },
      {
        text: $t("components.thg.ThgVehicleTable.table.year"),
        value: "year",
        type: "string",
        width: "100px"
      },
      { text: $t("common.nouns.customer"), value: "userId", type: "string" },
      { text: $t("common.nouns.licenseplate"), value: "numberplate", type: "string" },
      { text: $t("common.nouns.vehicleClass"), value: "registration.vehicleClass", type: "string" }
    ];
  }

  user(id: string): ThgUserBaseViewModelGen | undefined {
    const foundUser = ThgAnalyticsModule.thgCustomerData.data.find(entry => entry.user.id === id);
    if (foundUser) {
      return foundUser.user;
    }
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }
}
