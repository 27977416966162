





















































































import ThgChargingStationTransferDialog from "@/components/thg/ThgChargingStationTransferDialog.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { PartnerColor } from "@/lib/partnerColor";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { IAdminUser } from "@/models/user.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { CreateMeterReadingAdmin } from "@/store/models/thg/meter-reading";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomerContactCard from "../partner/CustomerContactCard.vue";
import Debug from "../utility/Debug.vue";
import ThgChargingStationInformationCard from "./ThgChargingStationInformationCard.vue";
import ThgMeterReadingCreateDialog from "./ThgMeterReadingCreateDialog.vue";
import ThgMeterReadingDetailTable from "./ThgMeterReadingDetailTable.vue";

@Component({
  components: {
    Debug,
    ThgChargingStationInformationCard,
    CustomerContactCard,
    ThgChargingStationTransferDialog,
    ThgMeterReadingDetailTable,
    ThgMeterReadingCreateDialog
  }
})
export default class ThgChargingStationDetailInformationCard extends Vue {
  @Prop()
  selectedUser!: IAdminUser;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get fullname() {
    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  get selection() {
    return ThgPortalModule.selectedChargingStation;
  }

  get meterReadings() {
    return ThgPortalModule.meterReadingForChargingStation;
  }

  get createdDate() {
    return this.date(this.selection);
  }

  date(item: any) {
    if (item.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(item.timestamp.created, locale);
    }
    return "";
  }

  @Prop()
  loading!: boolean;

  /**
   * Internal loading state
   */
  isLoading = false;

  partnerColor = new PartnerColor();
  thgStatusMap = thgStatusMap;

  get charging() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.charging);
  }

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  get commissioningDate() {
    return this.toDate(this.selection.commissioningDate);
  }

  get partner() {
    return PartnerModule.partner;
  }

  toDate(date: string) {
    return new Date(date).toLocaleString("de-DE", this.dateOptions as any);
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }

  toDetailView() {
    this.$router.push({
      name: "ThgChargingStationDetailView",
      params: {
        chargingStationId: this.selection.id
      }
    });
  }

  async createMeterReading(data: CreateMeterReadingAdmin) {
    this.$log.debug(data);
    this.isLoading = true;

    try {
      const partnerId = this.partner.id;
      const chargingStationId = ThgPortalModule.selectedChargingStation.id;
      if (this.selectedUser) data.userId = this.selectedUser?._id;

      await ThgPortalModule.createMeterReading({ partnerId, chargingStationId, dto: data });

      await ThgPortalModule.getMeterReadingForChargingStation(chargingStationId);

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  toEditView() {
    this.$router.push({
      name: "ThgChargingStationUpdateView",
      params: {
        chargingStationId: this.selection.id
      }
    });
  }
}
