var render = function() {
  var _vm$selectedUser,
    _vm$selectedUser$cont,
    _vm$selectedUser2,
    _vm$selectedUser2$con,
    _vm$selectedChargingS,
    _vm$selectedChargingS2,
    _vm$selectedChargingS3

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "wrapper"
        },
        [
          _vm.loading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
            : !_vm.loading && _vm.isSelectedMeterReading
            ? _c(
                "v-card",
                {
                  attrs: {
                    flat: "",
                    "max-width": "1200px"
                  }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pt-4"
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "text-truncate"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedMeterReading.meterReading
                                  .amountInKwh
                              ) +
                              " KW/h (" +
                              _vm._s(
                                _vm.selectedMeterReading.meterReading
                                  .chargingStationId
                              ) +
                              "/" +
                              _vm._s(_vm.selectedMeterReading.id) +
                              ") "
                          )
                        ]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _c(
                    "v-card-subtitle",
                    [
                      _vm._v(" " + _vm._s(_vm.createdDate) + " "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "nudge-right": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on
                                return [
                                  _vm.displayToDetailView
                                    ? _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: ""
                                            },
                                            on: {
                                              click: _vm.toDetailView
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-open-in-new")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Zur Detail Ansicht")])]
                      ),
                      _c("thg-meter-reading-transfer-dialog"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            loading: _vm.isLoadingActivities
                          },
                          on: {
                            click: function click($event) {
                              _vm.isTimeLineSideCard = !_vm.isTimeLineSideCard
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(" mdi-chart-timeline-variant-shimmer ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "scrollable virtual-scroller",
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("debug", {
                        attrs: {
                          debug: _vm.selectedMeterReading
                        }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                md: "6",
                                cols: "12"
                              }
                            },
                            [
                              _c("thg-quote-detail-information-status-card", {
                                attrs: {
                                  thg: _vm.selectedMeterReading,
                                  cb: _vm.updateStatusCb
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-col", {
                            attrs: {
                              md: "6",
                              cols: "12"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                md: "6",
                                cols: "12"
                              }
                            },
                            [
                              _vm.selectedMeterReading
                                ? _c("thg-meter-reading-information-card", {
                                    attrs: {
                                      meterReading: _vm.selectedMeterReading,
                                      loading: _vm.isLoading
                                    },
                                    on: {
                                      save: _vm.saveMeterReading
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                md: "6",
                                cols: "12"
                              }
                            },
                            [
                              _c("customer-contact-card", {
                                attrs: {
                                  userId: _vm.selectedUser._id,
                                  companyName: _vm.selectedUser.company,
                                  customerName: _vm.fullname,
                                  address: _vm.selectedUser.address,
                                  email:
                                    (_vm$selectedUser = _vm.selectedUser) ===
                                      null || _vm$selectedUser === void 0
                                      ? void 0
                                      : (_vm$selectedUser$cont =
                                          _vm$selectedUser.contact) === null ||
                                        _vm$selectedUser$cont === void 0
                                      ? void 0
                                      : _vm$selectedUser$cont.email,
                                  phone:
                                    (_vm$selectedUser2 = _vm.selectedUser) ===
                                      null || _vm$selectedUser2 === void 0
                                      ? void 0
                                      : (_vm$selectedUser2$con =
                                          _vm$selectedUser2.contact) === null ||
                                        _vm$selectedUser2$con === void 0
                                      ? void 0
                                      : _vm$selectedUser2$con.phone
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                md: "6",
                                cols: "12"
                              }
                            },
                            [
                              _c("thg-charging-station-information-card", {
                                attrs: {
                                  chargingStation: _vm.selectedChargingStation,
                                  nav: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                md: "6",
                                cols: "12"
                              }
                            },
                            [
                              _c("customer-contact-card", {
                                attrs: {
                                  companyName:
                                    (_vm$selectedChargingS =
                                      _vm.selectedChargingStation
                                        .chargingPointOperator) === null ||
                                    _vm$selectedChargingS === void 0
                                      ? void 0
                                      : _vm$selectedChargingS.name,
                                  customerName:
                                    (_vm$selectedChargingS2 =
                                      _vm.selectedChargingStation
                                        .chargingPointOperator) === null ||
                                    _vm$selectedChargingS2 === void 0
                                      ? void 0
                                      : _vm$selectedChargingS2.managingDirectorName,
                                  address:
                                    (_vm$selectedChargingS3 =
                                      _vm.selectedChargingStation
                                        .chargingPointOperator) === null ||
                                    _vm$selectedChargingS3 === void 0
                                      ? void 0
                                      : _vm$selectedChargingS3.address
                                }
                              }),
                              _c("thg-quote-detail-information-contract-card", {
                                staticClass: "mt-5",
                                attrs: {
                                  thg: _vm.selectedMeterReading,
                                  user: _vm.selectedUser,
                                  loading: _vm.isLoading
                                },
                                on: {
                                  save: _vm.savePayoutConfiguration
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.hasChargingStationMeterReadings
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12"
                                  }
                                },
                                [
                                  _vm.meterReadings
                                    ? _c("thg-meter-reading-detail-table", {
                                        attrs: {
                                          meterReadings: _vm.meterReadings
                                        }
                                      })
                                    : _c(
                                        "v-card",
                                        [
                                          _c("v-skeleton-loader", {
                                            attrs: {
                                              type: "v-table"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "emptyDataContainer"
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref2) {
                            var on = _ref2.on
                            return [
                              _c(
                                "v-img",
                                _vm._g(
                                  {
                                    attrs: {
                                      src: require("@/assets/undraw/undraw_no_data_re_kwbl.svg"),
                                      height: "100",
                                      width: "200",
                                      contain: ""
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerReportList.noData"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.selectedMeterReading
        ? _c("thg-quote-detail-card-activity-time-line-side-card", {
            attrs: {
              selectedUser: _vm.selectedUser,
              hidden: !_vm.isTimeLineSideCard,
              thg: _vm.selectedMeterReading,
              partner: _vm.partner
            },
            on: {
              close: function close($event) {
                _vm.isTimeLineSideCard = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }