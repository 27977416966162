var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            width: "800px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            disabled: _vm.thgs.length <= 0,
                            color: "warning",
                            elevation: "0"
                          },
                          on: {
                            click: _vm.initialize
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Auszahlungsbetrag ändern ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4",
                  class: _vm.color
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-truncate"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.thg.ThgBatchUpdateDialog.title")
                        )
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          },
                          on: {
                            click: _vm.closeDialog
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.thg.ThgBatchUpdateDialog.subtitle", {
                        count: _vm.thgs.length,
                        processingTimeInSeconds: Math.round(
                          (_vm.thgs.length *
                            (_vm.averageUpdateInMs + _vm.sleepInMs)) /
                            60
                        )
                      })
                    ) +
                    " "
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.isValid,
                        callback: function callback($$v) {
                          _vm.isValid = $$v
                        },
                        expression: "isValid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "7"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [_vm.minZeroNumberRule],
                                  label: _vm.$t(
                                    "components.thg.ThgQuoteDetailInformationContractCard.changePayout"
                                  ),
                                  type: "number",
                                  required: "",
                                  outlined: "",
                                  "data-test-revenue": "",
                                  "append-icon": "mdi-currency-eur"
                                },
                                model: {
                                  value: _vm.revenue,
                                  callback: function callback($$v) {
                                    _vm.revenue = _vm._n($$v)
                                  },
                                  expression: "revenue"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "2"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mt-3 mb-n3 pa-2"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgQuoteDetailInformationContractCard.per"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      rules: [_vm.minZeroNumberRule],
                                      items: _vm.units,
                                      label: _vm.$t(
                                        "components.thg.ThgQuoteDetailInformationContractCard.quantity"
                                      ),
                                      type: "number",
                                      required: "",
                                      outlined: "",
                                      "data-test-revenue": ""
                                    },
                                    model: {
                                      value: _vm.quantity,
                                      callback: function callback($$v) {
                                        _vm.quantity = _vm._n($$v)
                                      },
                                      expression: "quantity"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "3"
                              }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  rules: [_vm.requiredRule],
                                  items: _vm.units,
                                  label: _vm.$t(
                                    "components.thg.ThgQuoteDetailInformationContractCard.unit"
                                  ),
                                  required: "",
                                  outlined: "",
                                  "data-test-revenue": ""
                                },
                                model: {
                                  value: _vm.unit,
                                  callback: function callback($$v) {
                                    _vm.unit = $$v
                                  },
                                  expression: "unit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "components.thg.ThgBatchUpdateDialog.isFixed"
                          ),
                          hint: _vm.$t(
                            "components.thg.ThgBatchUpdateDialog.isFixedHint"
                          ),
                          outlined: "",
                          required: "",
                          "data-test-subtitle": ""
                        },
                        model: {
                          value: _vm.isFixed,
                          callback: function callback($$v) {
                            _vm.isFixed = $$v
                          },
                          expression: "isFixed"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.vins) + " ")]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.isValid,
                        loading: _vm.isLoading
                      },
                      on: {
                        click: _vm.save
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }