import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ChurnEntityAnalysis, IChurnEntityAnalysis } from "./churn-entity.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class ChurnBase {
  /** Id of the cost object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.id"
  })
  id: string;

  @FilterConfig({ displayName: "objects.churn.year", type: FilterTypes.NUMBER })
  year: number;

  @FilterConfig({
    displayName: "objects.churn.countryCode",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(c => ({
        text: `CountryCodeEnum.${c}`,
        value: c
      })),
      itemValue: "value"
    }
  })
  countryCode: CountryCodeEnum;

  @FilterConfig({ type: ChurnEntityAnalysis })
  thg: IChurnEntityAnalysis;

  @FilterConfig({ type: ChurnEntityAnalysis })
  user: IChurnEntityAnalysis;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  constructor(data?: Partial<ChurnBase>) {
    this.id = data?.id || "";
    this.year = data?.year ?? new Date().getFullYear();
    this.countryCode = data?.countryCode ?? CountryCodeEnum.germany;
    this.thg = new ChurnEntityAnalysis(data?.thg);
    this.user = new ChurnEntityAnalysis(data?.user);
    this.timestamp = new Timestamp(data?.timestamp);
  }
}

type IChurn = ChurnBase;
const Churn = Filter.createForClass(ChurnBase);

export { Churn, IChurn };
