






















import ThgQuoteDetailCard from "@/components/thg/ThgQuoteDetailCard.vue";
import ThgQuoteListCard from "@/components/thg/ThgQuoteListCard.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { handleError } from "@/lib/utility/handleError";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    ThgQuoteListCard,
    TheLayoutPortalSplit,
    ThgQuoteDetailCard
  }
})
export default class ThgQuote extends mixins(PaginationListVueMixin, PartnerFallbackMixin) {
  isLoadingDetail = false;
  selectedUser: IAdminUser | null = null;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get getloadingSelectedThg() {
    return this.isLoadingDetail;
  }

  setloadingSelectedThg(isLoading: any) {
    this.isLoadingDetail = isLoading;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.refresh({ partnerId: this.partnerId });

    const item = this.getModule().paginationList[0];
    if (item) {
      await this.loadDetail(item);
    }
  }

  getModule(): PaginatedBaseStore<any, any> {
    return ThgPortalModule;
  }

  async set(item: any) {
    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({ name: "ThgQuoteDetailView", params: { thgId: item.id, partnerId: this.partnerId } });
    } else {
      await this.loadDetail(item);
    }
  }

  async loadDetail(thg: IThg) {
    try {
      this.isLoadingDetail = true;
      await ThgPortalModule.getSelectedThg(thg.id);

      this.selectedUser = await AdminUserPaginationModule.getSelectedUser(thg.userId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDetail = false;
    }
  }
}
