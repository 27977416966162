var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-row",
        {
          staticClass: "mt-2 mb-2 mr-2 ml-2"
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                xs: "12",
                sm: "12",
                md: "4",
                lg: "4",
                xl: "4",
                col: "12"
              }
            },
            [
              _c("analytics-query-filter", {
                attrs: {
                  field: "year",
                  items: _vm.years,
                  labelText: _vm.$t(
                    "views.thgPortal.ThgAnalytics.yearFilterLabel"
                  )
                },
                on: {
                  change: _vm.onQueryFilterChange
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                xs: "12",
                sm: "12",
                md: "4",
                lg: "4",
                xl: "4",
                col: "12"
              }
            },
            [
              _c("analytics-query-filter", {
                attrs: {
                  field: "countryCode",
                  items: _vm.countries,
                  labelText: _vm.$t(
                    "views.thgPortal.ThgAnalytics.countryFilterLabel"
                  )
                },
                on: {
                  change: _vm.onQueryFilterChange
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                xs: "12",
                sm: "12",
                md: "4",
                lg: "4",
                xl: "4",
                col: "12"
              }
            },
            [
              _c("analytics-date-range-selector-dialog", {
                attrs: {
                  dates: _vm.dates
                },
                on: {
                  setDate: _vm.setDateRange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "mx-2 mb-2",
          model: {
            value: _vm.tabs,
            callback: function callback($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs"
          }
        },
        [
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("views.thgPortal.ThgAnalytics.tabs.overview")))
          ]),
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("views.thgPortal.ThgAnalytics.tabs.user")))
          ]),
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("views.thgPortal.ThgAnalytics.tabs.thg")))
          ])
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c(
            "v-tabs-items",
            {
              staticClass: "my-4",
              model: {
                value: _vm.tabs,
                callback: function callback($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs"
              }
            },
            [
              _c(
                "v-tab-item",
                {
                  key: "0"
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "4",
                            cols: "12"
                          }
                        },
                        [
                          _c("time-series-line-chart-card", {
                            attrs: {
                              loading: _vm.dataHandler.realTimeData.isLoading,
                              kpiGroup: _vm.realTimeTrendKpiGroup,
                              data: _vm.realTimeAnalysisData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.realtimeAnalysis"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "8",
                            md: "8",
                            cols: "12"
                          }
                        },
                        [
                          _c("time-series-line-chart-card", {
                            attrs: {
                              loading: _vm.dataHandler.trendAnalysis.isLoading,
                              kpiGroup: _vm.dayTrendKpiGroup,
                              data: _vm.dayTrendData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.daytrendAnalysis"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("time-series-line-chart-card", {
                            attrs: {
                              loading: _vm.dataHandler.trendAnalysis.isLoading,
                              data: _vm.userTrendData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.userTrendAnalysis"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("time-series-line-chart-card", {
                            attrs: {
                              loading: _vm.dataHandler.trendAnalysis.isLoading,
                              data: _vm.trendData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.trendAnalysis"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("time-series-line-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.meaterReadingTrendAnalysis
                                  .isLoading,
                              data: _vm.meaterReadingTrendData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.meterReadingTrendAnalysis",
                              yAxixFactor: 1000,
                              yAxixAppendix: " MWh"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("time-series-line-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.promotionTrendAnalysis
                                  .isLoading,
                              data: _vm.promotionTrendData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.promotionTrendAnalysis"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("bar-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.partnerDistribution.isLoading,
                              data: _vm.partnerDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.topPartner"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  key: "1"
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading: _vm.dataHandler.marketingOptIn.isLoading,
                              data: _vm.userIsCompanyDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.isCompanyDistribution",
                              reverseAxis: true,
                              sort: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading: _vm.dataHandler.marketingOptIn.isLoading,
                              data: _vm.marketingDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.marketingOptIn",
                              reverseAxis: true,
                              sort: false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  key: "2"
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("scatter-line-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.thgPerUserStats.isLoading,
                              data: _vm.thgUserStats,
                              exportData: _vm.thgUserStatsExportData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.thgPerUserStats"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.yearAnalysisData.isLoading,
                              data: _vm.yearDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.yearDistribution",
                              reverseAxis: true,
                              sort: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.yearAnalysisData.isLoading,
                              data: _vm.isMultiYearDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.isMultiYearDistribution",
                              reverseAxis: true,
                              sort: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("bar-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.weekdayDistribution.isLoading,
                              data: _vm.weekdayDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.weekdayDistribution",
                              reverseAxis: true,
                              sort: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("bar-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.hourDistribution.isLoading,
                              data: _vm.hourDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.hourDistribution",
                              reverseAxis: true,
                              sort: false,
                              showMaxItems: 24
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.vehicleClassDistribution
                                  .isLoading,
                              data: _vm.vehicleClassDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.vehicleClassDistribution"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.impactTypeDistribution
                                  .isLoading,
                              data: _vm.impactTypeDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.impactTypeDistribution"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.statusDistribution.isLoading,
                              data: _vm.statusDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.statusTypeDistribution"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("bar-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.affiliateDistribution.isLoading,
                              data: _vm.affiliateDistributionData,
                              title:
                                "views.thgPortal.ThgAnalytics.title.topAffiliates"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.partnerDistribution.isLoading,
                              data: _vm.partnerTypeDistribution,
                              title:
                                "views.thgPortal.ThgAnalytics.title.parnterTypeDistribution"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            lg: "4",
                            md: "6",
                            cols: "12"
                          }
                        },
                        [
                          _c("pie-chart-card", {
                            attrs: {
                              loading:
                                _vm.dataHandler.confirmedByDistribution
                                  .isLoading,
                              data: _vm.confirmedByDistribution,
                              title:
                                "views.thgPortal.ThgAnalytics.title.confirmedByDistribution"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }