




























































































































































































































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBillingPdfInputGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import { IBilling } from "@/models/billing.entity";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { phoneRule, emailRule } from "@/lib/rules/contactRule";

@Component({ components: {} })
export default class ThgBillingCreateNewBillingManualPdf extends DarkModeHighlightMixin {
  @Prop()
  value!: ThgBillingPdfInputGen;

  @Prop()
  billing!: IBilling;

  @Prop()
  isValid!: boolean;

  get valid() {
    return this.isValid;
  }

  set valid(value: boolean) {
    this.$emit("update:isValid", value);
  }

  isLoading = false;

  readonly CountryCodes = Object.values(CountryCodeEnum);

  get requiredRule() {
    return [requiredRule()];
  }

  get phoneRule() {
    return [phoneRule()];
  }

  get emailRule() {
    return [emailRule()];
  }

  addBillingItem() {
    this.value.billingItems.push({
      amount: 0,
      isVat: true,
      product: {
        isDeductible: false,
        name: "",
        pricePerProduct: 0,
        accountingSuffix: ""
      },
      totalPriceWithoutTax: 0,
      totalPriceWithVat: 0,
      vat: 0
    });
  }
}
