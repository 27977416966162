var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.isDialogActive,
        title: _vm.$t("components.ThgVehicleIsAutoRenewalDialog.title"),
        subtitle: _vm.subtitle,
        rightLoading: _vm.isLoading,
        leftDisabled: _vm.isLoading,
        persistent: true,
        supressKeyboardConfirm: true
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        leftClick: function leftClick($event) {
          _vm.isDialogActive = false
        },
        rightClick: _vm.update
      }
    },
    [
      _c("v-checkbox", {
        attrs: {
          disabled: _vm.isLoading,
          label: _vm.$t("components.ThgVehicleIsAutoRenewalDialog.label")
        },
        model: {
          value: _vm.isAutoRenewal,
          callback: function callback($$v) {
            _vm.isAutoRenewal = $$v
          },
          expression: "isAutoRenewal"
        }
      }),
      _vm.isLoading
        ? _c("span", [_vm._v(_vm._s(_vm.counter + "/" + _vm.vehicles.length))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }