






















import { IDialog } from "@/lib/interfaces/dialog.interface";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IThgVehicle } from "@/models/thg-vehicle.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgVehicleIsAutoRenewalDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  vehicles!: IThgVehicle[];

  get subtitle() {
    return this.$t("components.ThgVehicleIsAutoRenewalDialog.subtitle", { count: this.vehicles.length });
  }

  isDialogActive = false;
  isLoading = false;
  isAutoRenewal = false;

  counter = 0;

  async update() {
    try {
      this.isLoading = true;
      for (const vehicle of this.vehicles) {
        vehicle.isAutoRenewal = this.isAutoRenewal;
        await vehicle.updateOne();
        this.counter = this.counter + 1;
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.reset();
      this.isDialogActive = false;
    }
  }

  reset() {
    this.isLoading = false;
    this.isAutoRenewal = false;
    this.counter = 0;
  }

  open() {
    this.reset();
    this.isDialogActive = true;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.isDialogActive = v;
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
