























































import { simpleDate } from "@/lib/utility/date-helper";
import { IThgVehicle, ThgVehicle } from "@/models/thg-vehicle.entity";
import { IThg } from "@/models/thg.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import ThgVehicleDecommissionDialog from "../thg-vehicle/ThgVehicleDecommissionDialog.vue";
import ThgVehicleIsAutoRenewalDialog from "../thg-vehicle/ThgVehicleIsAutoRenewalDialog.vue";
import ThgVehicleStatusDialog from "../thg-vehicle/ThgVehicleStatusDialog.vue";
import Card from "../utility/Card.vue";
import { ThgVehicleModule } from "@/store/modules/thg-vehicle.store";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { Card, ThgVehicleDecommissionDialog, ThgVehicleIsAutoRenewalDialog, ThgVehicleStatusDialog },
  filters: { simpleDate }
})
export default class ThgQuoteDetailInformationVehicleCard extends Vue {
  @Prop({ default: undefined })
  thg!: IThg;

  loading = false;
  updateDialog = false;
  form = false;

  get vehicle(): IThgVehicle | undefined {
    return ThgVehicleModule.thgVehicle;
  }

  set vehicle(vehicle: IThgVehicle | undefined) {
    ThgVehicleModule.setVehicle(vehicle);
  }

  get controlElements() {
    return [
      {
        icon: "mdi-certificate",
        text: "Abmelden",
        action: this.openDecommissionDialog,
        disabled: false
      },
      {
        icon: "mdi-account",
        text: "Abostatus verwalten",
        action: this.openIsAutoRenewalDialog,
        disabled: false
      },
      {
        icon: "mdi-account",
        text: "Status ändern",
        action: this.openStatusDialog,
        disabled: false
      }
    ];
  }

  openDecommissionDialog() {
    (this.$refs.decommission as ThgVehicleDecommissionDialog)?.open();
  }

  openIsAutoRenewalDialog() {
    (this.$refs.autorenewal as ThgVehicleIsAutoRenewalDialog)?.open();
  }

  openStatusDialog() {
    (this.$refs.status as ThgVehicleStatusDialog)?.open();
  }

  async mounted() {
    this.loading = true;
    if (this.thg.vehicleId) {
      this.vehicle = new ThgVehicle({ id: this.thg.vehicleId });
      try {
        await this.vehicle.fetch();
      } catch (error) {
        handleError(error);
      }
    } else {
      this.vehicle = undefined;
    }

    this.loading = false;
  }
}
