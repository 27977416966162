



























































































import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { ActivePrice } from "@/store/models/thg/active-price";
import { CreatePriceDto } from "@/store/models/thg/create-price.dto";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RulesEnum, rules } from "@/lib/rules/rules.map";

@Component({
  components: {}
})
export default class ThgPriceForm extends Vue {
  readonly RulesEnum = RulesEnum;
  readonly rules = rules;

  @Prop()
  partners!: PartnerEntity[];

  @Prop()
  value?: CreatePriceDto;

  partner: PartnerEntity = this.partners[0];
  newPrice: CreatePriceDto = this.value || new CreatePriceDto();

  get price() {
    const activePrice = new ActivePrice([this.newPrice], this.partner.countryCode as CountryCodeEnum);

    return activePrice.get({ year: this.newPrice.year, vehicleClass: ThgVehicleTypes.M1 });
  }

  get availableVehicleClasses() {
    return new ActivePrice([this.newPrice], this.partner.countryCode as CountryCodeEnum).vehicleClass;
  }

  @Prop({ default: false })
  isLoading!: boolean;

  valid = false;

  getPrice(year: number, vehicleClass: ThgVehicleTypes) {
    return new ActivePrice([this.newPrice], this.partner.countryCode as CountryCodeEnum).get({
      year: year,
      vehicleClass: vehicleClass
    });
  }

  @Watch("partner")
  watchPartner() {
    if (this.partner) {
      this.newPrice.partner = this.partner;
    }
  }

  @Watch("newPrice")
  emitPrice(): CreatePriceDto {
    this.$emit("input", this.newPrice);

    return this.newPrice;
  }

  @Watch("valid")
  emitValid(): boolean {
    this.$emit("update:valid", this.valid);

    return this.valid;
  }

  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  created() {
    if (this.partner) {
      this.newPrice.partner = this.partner;
      this.$emit("input", this.newPrice);

      return this.newPrice;
    }
  }
}
