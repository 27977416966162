var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.isLoading
    ? _c(
        "v-form",
        {
          ref: "priceForm",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "6"
                  }
                },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.partners,
                          "item-text": "companyUsername",
                          "return-object": true
                        },
                        model: {
                          value: _vm.partner,
                          callback: function callback($$v) {
                            _vm.partner = $$v
                          },
                          expression: "partner"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          type: "number",
                          label: "Jahr",
                          rules: _vm.requiredRules
                        },
                        model: {
                          value: _vm.newPrice.year,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "year", _vm._n($$v))
                          },
                          expression: "newPrice.year"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "9"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          type: "number",
                          label: "Auszahlungsbetrag",
                          suffix: "€/MWH",
                          rules: _vm.requiredRules
                        },
                        model: {
                          value: _vm.newPrice.value,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "value", _vm._n($$v))
                          },
                          expression: "newPrice.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "3"
                      }
                    },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "fixed"
                        },
                        model: {
                          value: _vm.newPrice.isFixed,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "isFixed", $$v)
                          },
                          expression: "newPrice.isFixed"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          type: "number",
                          label: "Provision Partner",
                          suffix: "€/MWH",
                          rules: _vm.requiredRules
                        },
                        model: {
                          value: _vm.newPrice.commission,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "commission", _vm._n($$v))
                          },
                          expression: "newPrice.commission"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          label: "Titel"
                        },
                        model: {
                          value: _vm.newPrice.title,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "title", $$v)
                          },
                          expression: "newPrice.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          label: "Untertitel"
                        },
                        model: {
                          value: _vm.newPrice.subtitle,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "subtitle", $$v)
                          },
                          expression: "newPrice.subtitle"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          label: "Beschreibung"
                        },
                        model: {
                          value: _vm.newPrice.description,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "description", $$v)
                          },
                          expression: "newPrice.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          number: "",
                          label: "Hinweis"
                        },
                        model: {
                          value: _vm.newPrice.hint,
                          callback: function callback($$v) {
                            _vm.$set(_vm.newPrice, "hint", $$v)
                          },
                          expression: "newPrice.hint"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "6"
                  }
                },
                _vm._l(_vm.availableVehicleClasses, function(products, i) {
                  return _c(
                    "v-sheet",
                    {
                      key: i,
                      staticClass: "mb-2 py-n6",
                      attrs: {
                        outlined: "",
                        rounded: ""
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: ""
                          }
                        },
                        [
                          _c(
                            "v-list",
                            {
                              attrs: {
                                "three-line": ""
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      attrs: {
                                        tile: "",
                                        size: "60"
                                      }
                                    },
                                    [
                                      products.src
                                        ? _c("v-img", {
                                            staticStyle: {
                                              "border-radius": "4px"
                                            },
                                            attrs: {
                                              src: products.src
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(_vm.$t(products.title)) + " "
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        products.hint
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(products.hint) + " "
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(products.classSpeaking)
                                          ) + " "
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.newPrice.year +
                                                ": " +
                                                _vm.$t(
                                                  _vm.getPrice(
                                                    _vm.newPrice.year,
                                                    products.vehicleType
                                                  ).prefix
                                                ) +
                                                " " +
                                                _vm.getPrice(
                                                  _vm.newPrice.year,
                                                  products.vehicleType
                                                ).totalAmountAsString
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }