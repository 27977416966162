var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "card",
        {
          attrs: {
            outlined: "",
            "min-width": "250px",
            flat: "",
            title: _vm.$t(
              "components.ThgQuoteDetailInformationVehicleCard.title"
            ),
            loading: _vm.loading
          },
          scopedSlots: _vm._u(
            [
              _vm.vehicle
                ? {
                    key: "actions",
                    fn: function fn() {
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "nudge-bottom": -14,
                              "close-on-content-click": false
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function fn(_ref) {
                                    var on = _ref.on,
                                      attrs = _ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-dots-vertical")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3221905750
                            )
                          },
                          [
                            _c(
                              "v-list",
                              _vm._l(_vm.controlElements, function(
                                controlElement,
                                index
                              ) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    attrs: {
                                      disabled: controlElement.disabled || false
                                    },
                                    on: {
                                      click: function click($event) {
                                        return controlElement.action(_vm.item)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(controlElement.text))
                                    ]),
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled:
                                                controlElement.disabled || false
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(controlElement.icon)
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.loading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line"
                }
              })
            : !_vm.vehicle
            ? _c(
                "div",
                [
                  _c("v-card-text", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.ThgQuoteDetailInformationVehicleCard.noVehicle"
                        )
                      )
                    )
                  ])
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.ThgQuoteDetailInformationVehicleCard.vehicleId"
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.thg.vehicleId)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.ThgQuoteDetailInformationVehicleCard.isAutoRenewal"
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.vehicle.isAutoRenewal)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.ThgQuoteDetailInformationVehicleCard.identificationnumber"
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.vehicle.identificationnumber)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.ThgQuoteDetailInformationVehicleCard.vehicleState"
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.vehicle.vehicleState)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.ThgQuoteDetailInformationVehicleCard.decommissioningDate"
                          )
                        ) +
                        ": " +
                        _vm._s(_vm.vehicle.decommissioningDateReadable)
                    ),
                    _c("br")
                  ])
                ],
                1
              )
        ],
        1
      ),
      _c("thg-vehicle-decommission-dialog", {
        ref: "decommission",
        attrs: {
          vehicles: [_vm.vehicle]
        }
      }),
      _c("thg-vehicle-is-auto-renewal-dialog", {
        ref: "autorenewal",
        attrs: {
          vehicles: [_vm.vehicle]
        }
      }),
      _c("thg-vehicle-status-dialog", {
        ref: "status",
        attrs: {
          vehicles: [_vm.vehicle]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }