




















































































































import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { detailedDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ThgBillingPartnerViewModelGen } from "@/services/thg/v1/data-contracts";
import { PartnerBillingModule } from "@/store/modules/partner-billing.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: {
    Card
  }
})
export default class ThgBillingNumberPartnerDetail extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  get taxAccounts() {
    return [47600, 34000];
  }

  get billingData() {
    return PartnerBillingModule.currentDocument;
  }

  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  get displayToDetailView() {
    return this.$route.params.billingNumber === undefined;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get billingNumber() {
    return this.$route.params.billingNumber;
  }

  get pdf() {
    if (!this.billingData?.pdfBilling?.length) {
      return "";
    }
    return this.billingData.pdfBilling[0].url;
  }

  /**
   * Internationalization for billing types
   */
  get i18nBilling() {
    return this.$t("components.thg.thgBillingCreateNewBillingManual");
  }

  get i18n() {
    return this.$t("components.thg.ThgBillingNumberPartnerDetail");
  }

  get billingDate() {
    if (this.billingData?.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString") || "de-de";
      return detailedDate(this.billingData.timestamp.created.toString(), locale.toString());
    }
    return "";
  }

  get accountNumber() {
    return this.billingData?.accountNumber;
  }

  get withoutTax() {
    return this.totalSum - this.tax;
  }

  get withoutTaxText() {
    const tax = this.withoutTax;
    return tax.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }

  get tax() {
    let taxSum = 0;
    for (const accountRecord of this.billingData?.accountRecord || []) {
      if (accountRecord.account === this.accountNumber && this.taxAccounts.includes(accountRecord.contraAccount)) {
        taxSum += (accountRecord.amount / 1.19) * 0.19;
      }
    }

    return taxSum;
  }

  get taxText() {
    const tax = this.tax;
    return tax.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }

  get totalSum() {
    let sum = 0;
    for (const accountRecord of this.billingData?.accountRecord || []) {
      if (accountRecord.account === this.accountNumber) {
        sum += accountRecord.amount;
      }
    }

    return sum;
  }

  get totalText() {
    const sum = this.totalSum;

    return sum.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }

  toDetailView(billing: ThgBillingPartnerViewModelGen | null) {
    if (!billing) {
      return;
    }
    new GoToHelper(this.$router).goToBillingDetail(billing.billingNumber, this.partnerId);
  }

  toThgDetail(thgId: string) {
    new GoToHelper(this.$router).goToThgDetail(this.partnerId, thgId, true);
  }
}
